<script setup>
import { useToast } from 'vue-toastification';
import { onBeforeMount, reactive, watch } from 'vue';
import useWarehouses from '@/hooks/useWarehouses';

const toast = useToast()
const binData = reactive({
    warehouse_id: null,
    name: '',
    staging_area: null,
    not_inventory: null,
    holds_processable_damage: null,
    holds_destructable_damage: null,
    barcode: '',
    notes: ''
})
const { warehouses, fetchWarehouses } = useWarehouses()

const props = defineProps({
    btnTitle: {
        type: String,
        required: true
    },
    editBinData: {
        type: Object,
    }
})

const emit = defineEmits(['binActionHandler'])

onBeforeMount( async () => {
    await fetchWarehouses()
})

watch(() => props.editBinData, (newData) => {
    Object.assign(binData, newData);
}, { deep: true });

// const debouncedFetchClients = debounce( async (query) => {
//     clientUrlParams.filter.name.filter = query
//     await fetchClients(clientUrlParams);
// }, 300);

const save = () => {
    if(!binData.warehouse_id) {
        toast.error('Please select a warehouse.')
        return
    }
    if(!binData.name) {
        toast.error('Please enter a bin name.')
        return
    }
    emit('binActionHandler', binData)
}

</script>

<template>
    <v-card flat class="mx-4 mt-4">
        <v-card-text>
            <v-row align="center">
                <v-col cols="12" sm="4">
                    <label for="warehouse">Warehouse <span class="text-red">*</span></label>
                </v-col>
                <v-col cols="12" sm="8">
                    <v-autocomplete
                        density="compact"
                        v-model="binData.warehouse_id"
                        :items="warehouses?.data"
                        item-title="name"
                        item-value="id"
                        id="warehouse"
                        hide-details="auto"
                        placeholder="Select a warehouse..."
                        name="warehouse"
                        variant="outlined"
                    ></v-autocomplete>
                </v-col>

                <v-divider/>

                <!-- Not used might need for future
                <v-col cols="12" sm="4">
                    <label for="client">Client</label>
                </v-col>
                <v-col cols="12" sm="8">
                    <v-autocomplete
                        v-model="binData.client_id"
                        density="compact"
                        :items="clients"
                        @update:search="(query) => debouncedFetchClients(query)"
                        item-title="name"
                        item-value="id"
                        id="client"
                        hide-details="auto"
                        label="Select a client..."
                        name="client"
                        variant="outlined"
                    ></v-autocomplete>
                </v-col>

                <v-divider/> -->

                <v-col cols="4">
                    <label for="Bin Name">Bin Name <span class="text-red">*</span></label>
                </v-col>
                <v-col cols="8">
                    <v-text-field
                        v-model="binData.name"
                        density="compact"
                        id="Bin Name"
                        hide-details="auto"
                        placeholder="Bin Name"
                        name="Bin Name"
                        variant="outlined"
                    ></v-text-field>
                </v-col>

                <v-divider/>

                <v-col cols="6" sm="4">
                    <label>Staging area?</label>
                </v-col>
                <v-col cols="6" sm="8">
                    <v-radio-group 
                        inline 
                        hide-details="auto" 
                        density="compact" 
                        v-model="binData.staging_area">
                            <v-radio
                                color="primary"
                                label="Yes"
                                :value="true"
                                class="me-4"
                            ></v-radio>
                            <v-radio
                                color="error"
                                label="No"
                                :value="false"
                            ></v-radio>
                    </v-radio-group>
                </v-col>

                <v-divider/>

                <v-col cols="6" sm="4">
                    <label>Counts towards inventory?</label>
                </v-col>
                <v-col cols="6" sm="8">
                    <v-radio-group 
                        inline 
                        hide-details="auto" 
                        density="compact" 
                        v-model="binData.not_inventory">
                            <v-radio
                                color="primary"
                                label="Yes"
                                :value="false"
                                class="me-4"
                            ></v-radio>
                            <v-radio
                                color="error"
                                label="No"
                                :value="true"
                            ></v-radio>
                    </v-radio-group>
                </v-col>

                <v-divider/>

                <v-col cols="6" sm="4">
                    <label>Holds processable damages?</label>
                </v-col>
                <v-col cols="6" sm="8">
                    <v-radio-group 
                        inline 
                        hide-details="auto" 
                        density="compact" 
                        v-model="binData.holds_processable_damage">
                            <v-radio
                                color="primary"
                                label="Yes"
                                :value="true"
                                class="me-4"
                            ></v-radio>
                            <v-radio
                                color="error"
                                label="No"
                                :value="false"
                            ></v-radio>
                    </v-radio-group>
                </v-col>

                <v-divider/>

                <v-col cols="6" sm="4">
                    <label>Holds destructable damages?</label>
                </v-col>
                <v-col cols="6" sm="8">
                    <v-radio-group 
                        inline 
                        hide-details="auto" 
                        density="compact"
                        v-model="binData.holds_destructable_damage">
                            <v-radio
                                color="primary"
                                label="Yes"
                                :value="true"
                                class="me-4"
                            ></v-radio>
                            <v-radio
                                color="error"
                                label="No"
                                :value="false"
                            ></v-radio>
                    </v-radio-group>
                </v-col>

                <v-divider/>

                <v-col cols="4">
                    <label for="Barcode">Barcode</label>
                </v-col>
                <v-col cols="8">
                    <v-text-field
                        v-model="binData.barcode"
                        density="compact"
                        id="Barcode"
                        hide-details="auto"
                        placeholder="Barcode"
                        name="Barcode"
                        variant="outlined"
                    ></v-text-field>
                </v-col>

                <v-divider/>

                <v-col cols="4" align-self="start">
                    <label for="Notes">Notes</label>
                </v-col>
                <v-col cols="8">
                    <v-textarea
                        v-model="binData.notes"
                        density="compact"
                        id="Notes"
                        hide-details="auto"
                        placeholder="Notes"
                        name="Notes"
                        variant="outlined"
                    ></v-textarea>
                </v-col>

            </v-row>
        </v-card-text>
    </v-card>

    <v-card-actions class="d-flex justify-end mx-2 mt-4">
        <v-btn 
            size="large" 
            variant="flat" 
            color="primary"
            @click="save"
            >
            {{btnTitle}}
        </v-btn>
    </v-card-actions>
</template>
